header.rivemo {
    width:100%;
    text-align:center;
    padding: 28px 0 20px;
    background-color: #fbca00;
    @media screen and (max-width: 1140px), screen and (max-height: 900px) {
        padding: 15px 0 15px;
    }
    @media screen and (max-width: 1450px){
        width:100%;
    }
    @media screen and (max-width: 850px){
        text-align:center;
        padding: 10px 0 0 0;
    }
}
.top-login-container{
  position: absolute;
  top:0px;
  right:0px;
  padding: 20px 12px;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 900px){
      position: static;
  }
  @media screen and (max-width: 900px){
      padding: 0px 12px 20px;
  }
  @media screen and (max-width: 600px){
      justify-content: center;
  }
  .top-login{
    text-align: left;
    &.dx {
      text-align: right;
      @media screen and (max-width: 600px){
          display: none;
      }
    }
    a {
      display: inline-block;
      font-size: 15px;
      font-family: 'Gotham Bold';
      text-decoration: none;
      margin: 0 10px;
      color: #009fe2;
      @media screen and (max-width: 1200px){
        font-size: 13px;
        margin: 0 5px;
      }
    }
  }
}
.logo{
  max-width:356px;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 1439px), screen and (max-height: 899px){
    max-width:250px;
    margin:0 25px 10px;
    height: auto;
  }
}


#logout{
  display: inline-block;
  cursor: pointer;
  font-size: 0.8em;
  img, strong{
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
  }
}
