.city-container{
  width:100%;
  padding-bottom:30px;
  text-align: center;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
.city-container .pimpinot{
  position: absolute;
  bottom:-17px;
  right:2%;
  z-index: 10;
}
@media screen and (max-width: 2200px){
  .city-container .pimpinot{ 
    width: 300px;
    bottom:-9px;
  }
}
@media screen and (max-width: 1800px){
  .city-container .pimpinot{ 
    width: 200px;
    bottom:-9px;
    right: 2%;
  }
}
@media screen and (max-width: 1550px){
  .city-container .pimpinot{ 
    display: none;
  }
}
.city-container h3 {
  font-family: 'Gotham Bold';
  font-size: 2em;
  color: #fff;
  padding:35px 0 15px;
  margin:0px;
}
@media screen and (max-width: 768px){
  .city-container h3 {
    font-size: 1.6em;
  }
}

.reparti-home{
    width: 100%;
    max-width: 1200px;
    padding:0px 0 30px;
    text-align: center;
    margin:auto;
    margin-top: 30px;
    position: relative;
    @media screen and (max-width: 1100px){
      margin-top: 20px;
    }
    @media screen and (max-width: 767px){
      margin-top: 20px;
    }
    .reparti-home-icons{
        width: 100%;
        color: #fbca00;
        color: #1F9DD9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
        .reparti-home-icon{
            width: 16.66%;
            color: #fff;
            font-family: 'Gotham Medium';
            font-size: 0.9em;
            padding: 0 20px;
            @media screen and (max-width: 1023px){
              width: 33.33%;
              margin-bottom: 20px;
            }
            @media screen and (max-width: 640px){
              padding: 0 5px;
              font-size: 0.8em;
            }
            .reparti-home-icon-img{
              display: block;
              height: 68px;
              text-align: center;
              margin-bottom: 10px;
              padding-top: 10px;
              @media screen and (max-width: 768px){
                  height: 55px;
              }
              img{
                max-width: 50%;
                max-height: 58px;
                object-fit: contain;
                display: inline-block;
                
                @media screen and (max-width: 768px){
                    max-height: 45px;
                }
              }
            }
        }
    }
    .zone-search {
        width: 100%;
        margin:auto;
        margin-bottom: 30px;
        text-align: center;
        /* background-color: rgba(255,255,255,0.21);
        box-shadow: 3px 3px 10px #00000061; */
        border-radius: 13px;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 1100px){
          margin-bottom: 25px;
        }
        h2 {
            font-family: 'Gotham Bold';
            font-size: 1.6em;
            padding:35px 0 0px;
            margin:0px;
            color: #fff;
            width: 100%;
            text-align: center;
            @media screen and (max-width: 1100px){
              font-size: 1.4em;
            }
            @media screen and (max-width: 767px){
              font-size: 1.3em;
            }
        }
    }
}
@keyframes colorchange
{
  0%   {box-shadow: 0px 0px 0px red;}
  50%  {box-shadow: 0px 0px 20px red;}
  100% {box-shadow: 0px 0px 0px red;}
}
.city-search {
  width: 90%;
  max-width: 575px;
  margin: auto;
  background-color: #fff;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.flash {
    animation: colorchange 1.2s;
  }
  @media screen and (max-width: 767px){
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
  }
  .city-input {
    width: 100%;
    max-width: 330px;
    padding-left: 30px;
    @media screen and (max-width: 767px){
      padding-left: 0px;
    }
    *{
      font-size: 1.05em!important;
      font-family: 'Gotham Medium'!important;
      color: #7E7E7E!important;
    }
  }
}
.city-btn {
  display: inline-block;
  padding: 12px 45px;
  border-radius: 23px;
  background-color: #fbca00;
  color: #1F9DD9;
  cursor: pointer;
  font-family: 'Gotham Bold';
  &.mob{
    display: none;
  }
  &:hover{
    background-color: #1F9DD9;
    color: #fff;
  }
  @media screen and (max-width: 767px){
    margin-top: 15px;
  }
  @media screen and (max-width: 560px){
    display: none;
    &.mob{
      display: inline-block;
    }
  }
}

.why-rivemo{
  width:100%;
  max-width: 1920px;
  margin:auto;
  padding-bottom:140px;
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (max-width: 768px){
    padding-bottom:50px;
  }
}
.why {
  padding:0px 16px 0 115px;
  margin-top: 35px;
  position: relative;
  height: 120px;
  text-align: left;
  font-size:1.125em;
  line-height: 1.2em;
  @media screen and (max-width: 1400px){
    font-size:1em;
      padding:0px 0px 0 55px;
  }
  @media screen and (max-width: 959px){
      font-size:1.2em;
      line-height: 1.2em;
  }
  @media screen and (max-width: 599px){
      font-size:1em;
      line-height: 1.4em;
  }
}
.why em {
  font-style: normal;
  position: relative;
  z-index: 1;
}
.num {
  position: absolute;
  top:15px;
  left:60px;
  color: #1F9DD940;
  font-family: 'Gotham Black';
  font-size: 7.5em;
  line-height: 0.7em;
  z-index: 0;
  @media screen and (max-width: 1400px){
    left:15px;
  }
}
svg, img {
  max-width: 100%;
}
.motivi-img{
  max-width: 70%;
  max-height: 150px;
  object-fit: contain;
  @media screen and (max-width: 1200px){
    max-width: 50%;
  }
  @media screen and (max-width: 500px){
    max-height: 100px;
    max-width: 35%;
  }
}


.app-rivemo{
  width:100%;
  padding: 70px 0 20px;
  background-color: #fbca00;
  position: relative;
}
.bg-app{
  width:57%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left:43%;
  top:0;
}
.ico-app{
  
  text-align: left;
  font-size: 2.5em;
  line-height: 1.2em;
}
.ico-app p{
  padding: 0px;
  margin: 35px 0 55px;
}
.iphone-app{
  text-align: right;
}
.ico-app .app-img {
  display: inline-block;
}
.ico-app .app-img:first-of-type {
  margin-right:20px;
}
@media screen and (max-width: 959px){
  .ico-app{
    padding-top:0px;
    padding-bottom:30px;
    text-align: center;
    font-size: 1.8em;
  }
  .iphone-app{
    text-align: center;
  }
  .iphone-app img{
    max-width:200px;
  }
}
@media screen and (max-width: 599px){
  .bg-app {
    display: none;
  }
}

.how-to{
  width:80%;
  max-width: 1920px;
  margin:auto;
  padding-bottom:100px;
  @media screen and (max-width: 768px){
      padding-bottom:50px;
  }
  @media screen and (max-width: 500px){
      padding-bottom:0px;
  }
  .slick-prev, .slick-next {
    width: auto !important;
    height: auto !important;
  }
  .slick-prev {
    left: -67px !important;
  }
  .slick-next {
    right: -67px !important;
    transform: translate(0, -50%) rotate(180deg) !important;
  }
  .slick-slide img {
      max-width: 100% !important;
  }
  .slide-text{
    line-height: 1.2em;
    padding-bottom:80px;
    max-width: 470px;
    @media screen and (max-width: 1600px){
      font-size:0.8em;
      line-height: 1.2em;
      padding-bottom:80px;
    }
    @media screen and (max-width: 960px){
      max-width: none;
      br{ display: none; }
      font-size:0.65em;
      text-align:center;
    }
    @media screen and (max-width: 725px){
      font-size: 0.4em;
      text-align:center;
      margin-top: 15px;
    }
  }
  .slick-dots {
      bottom: 110px !important;
      left: 60px !important;
  }
  .slick-dots li {
      margin: 0 18px 0 0 !important;
  }
  @media screen and (max-width: 1600px){
    .slick-dots {
        bottom: 80px !important;
        left: 40px !important;
    }
  }
  @media screen and (max-width: 1279px){
    .slick-dots {
        bottom: 25px !important;
        left: 0px !important;
    }
    .slick-dots li {
        margin: 0 10px !important;
    }
  }
  @media screen and (max-width: 725px){
    .how-to{ width:75%; }
    .slick-prev, .slick-next {
      width: 30px !important;
      height: 30px !important;
    }
    .slick-prev {
      left: -30px !important;
    }
    .slick-next {
      right: -30px !important;
      transform: translate(0, -50%) rotate(180deg) !important;
    }
  }
}

.home-form{
  width: 100%;
  background-color: #f6f6f6;
  padding-bottom: 80px;
  @media screen and (max-width: 768px){
    padding:0 15px 40px;
  }
  .home-form-row {
    width: 100%;
    max-width: 1230px;
    margin:auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @media screen and (max-width: 1023px){
      justify-content: center;
      flex-wrap: wrap;
      padding:0px;
    }
    .home-form-card{
      width: 48%;
      max-width: 575px;
      border-radius: 25px;
      background-color: #fff;
      box-shadow: 0px 0px 8px #00000029;
      font-size: 17px;
      color:#666;
      text-align: center;
      padding-bottom:25px;
      overflow: hidden;
      @media screen and (max-width: 1023px){
        width: 100%;
        margin-bottom: 25px;
        font-size: 15px;
      }
      .home-form-img{
        width: 100%;
        height: 383px;
        @media screen and (max-width: 1023px){
          height: 200px;
        }
        @media screen and (max-width: 767px){
          height: 150px;
        }
        &.azienda {
          background: transparent url('../../assets/form-azienda.jpg') center top no-repeat;
          background-size: cover;
        }
        &.rider {
          background: transparent url('../../assets/form-rider.jpg') center top no-repeat;
          background-size: cover;
        }
      }
      h3 {
        padding:30px 0 10px;
        margin:0px;
        text-align: center;
        font-size: 30px;
        font-family: 'Gotham Medium';
        color:#000;
        @media screen and (max-width: 1023px){
          padding:20px 0 10px;
          font-size: 21px;
        }
      }
      p {
        padding:0 40px;
        margin:0px 0 30px;
        @media screen and (max-width: 1023px){
          padding:0 20px;
          margin:0px 0 15px;
          line-height: 1.2em;
        }
      }
      .form-btn {
        display: block;
        margin:0px 40px;
        padding: 12px 45px;
        border-radius: 23px;
        background-color: #1F9DD9;
        color: #fff;
        cursor: pointer;
        font-family: 'Gotham Bold';
        font-size: 20px;
        &:hover{
          background-color: #fbca00;
          color: #fff;
        }
        @media screen and (max-width: 1023px){
          font-size: 16px;
          margin:0px 20px;
        }
      }
    }
  }
}

.page-dot{
  width:19px;
  height:19px;
  border:2px solid #fbca00;
  border-radius: 50%;
  text-indent: -9999px;
  margin-right: 20px;
}
.slick-active .page-dot{
  background: #fbca00;
}

.wanna-try{
  width:100%;
  padding: 50px 0 0px;
  background-color: #94d4e9;
  position: relative;
  text-align: center;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}
.wanna-try-content{
  width:100%;
  max-width:1920px;
  margin:auto;
  padding: 0px 0 80px;
  background-color: #94d4e9;
  position: relative;
  text-align: center;
  z-index: 2;
}
@media screen and (max-width: 540px){
  .wanna-try-content{
    padding: 0px 0 120px;
  }
}
.wanna-try h2{
  padding: 0px 0px 15px;
}
.wanna-try h4{
  margin: 0px 0px 80px;
}
@media screen and (max-width: 768px){
  .wanna-try h4{
    margin: 0px 0px 40px;
  }
}

.faro{
  position: absolute;
  left:20px;
  bottom:0px;
  z-index: 10;
}
.barca{
  position: absolute;
  right:20px;
  bottom:-15px;
  z-index: 10;
}
@media screen and (max-width: 1530px){
  .faro{
    left:-10px;
    bottom:0px;
    width: 130px;
  }
  .barca{
    right:-10px;
    bottom:-15px;
    width: 130px;
  }
}
@media screen and (max-width: 540px){
  .faro{
    width: 80px;
  }
  .barca{
    width: 100px;
  }
}