
.login-link {
    font-family: 'Gotham Bold';
    color:#1f9dd9 !important;
    cursor: pointer;
}
.login-form .MuiInputBase-input{
    padding-bottom:15px;
    padding-left:15px;
}
.login-form .MuiInputLabel-formControl{
    left:15px;
}
.login-form .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.34);
}
.login-form .MuiFormLabel-root.Mui-focused {
    color:#1f9dd9 !important;
}
.login-form .MuiInputBase-root {
    color: rgba(0, 0, 0, 0.57);
}
.login-form .MuiInput-underline:before {
    border-color: rgba(0, 0, 0, 0.57);
}
.login-form .MuiInput-underline:after {
    border-color: #1f9dd9 !important;
}

.other-logins{
    /* display: none; */
    text-align: center;
    padding-bottom: 50px;
    .kep-login-facebook{
        font-family: 'Gotham Bold';
        padding:10px 20px!important;
        border-radius: 25px!important;
    }
    button {
        opacity: 1!important;
        cursor: pointer;
    }
}
.gdpr {
      label {
        position: relative;
        padding-left: 30px;
        font-size: 15px;
        line-height: 1.2em;
        > span{
          display: block;
          margin:0px;
          position: absolute;
          left: 0;
          top: 0px;
          cursor: pointer;
          width: 20px;
          height: 20px;
          border-radius: 3px;
          background-color: #fff;
          border:1px solid #b5b5b5;
          z-index: 1;
        }
        a {
            text-decoration: underline;
            color: #1f9dd9;
        }
      }
      input {
        display: none;
        &:checked + label {
          span {
            border:1px solid #b5b5b5;
            z-index: 1;
            &:before {
              content: "";
              position: absolute;
              width: 10px;
              height: 10px;
              border-radius: 3px;
              background-color: #333;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
            }
          }
        } 
      }
    }