.top-shop {
  width: 100%;
  height: 34vh;
  min-height: 280px;
  padding-top: 17vh;
  position: relative;
  background-color: #c4e0e3;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-left: 15px;
  padding-right: 15px;
  &.no-image{
    background-size: contain;
  }
  @media screen and (max-width: 768px) {
      padding-top: 7vh;
      min-height: 200px;
  }
  @media screen and (min-height: 900px) and (max-width: 768px) {
      width: 100%;
      height: 23vh;
      min-height: 200px;
  }
  &.normal-page{
    padding-top: 5%;
    min-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    @media screen and (max-width: 768px) {
        min-height: 250px;
    }
    @media screen and (min-height: 900px) and (max-width: 768px) {
        width: 100%;
        height: 23vh;
        min-height: 200px;
    }
  }
  &.form-page{
    padding-top: 4%;
    min-height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #fff;
    height: auto;
    @media screen and (max-height: 900px), screen and (max-width: 1024px) {
        width: 100%;
        height: 13vh;
        min-height: 200px;
    }
    @media screen and (max-height: 700px), screen and (max-width: 767px) {
        width: 100%;
        height: 13vh;
        min-height: 160px;
    }
  }
  .shop-back-container{
    width: 1130px;
    max-width: 100%;
    margin:auto;
    height: 100%;
    position: relative;
  }
  .shop-back{
    position: absolute;
    left: 0;
    bottom: 15px;
    display: inline-block;
    background-color: #fbca00;
    padding: 5px 25px 3px;
    border-radius: 27px;
    color: #fff;
    cursor: pointer;
    font-family: 'Gotham Medium';
    font-size: 12px;
    &:hover{
      background-color: #009fe2;
      color: #fff;
    }
    @media screen and (max-width: 767px){
      margin-top: 15px;
    }
  }
}

.adesione{
  background-color: #f5f5f5;
  padding-bottom: 0px;
}
.step-adesione-container {
  width: 100%;
  background-color: #fff;
  padding: 15px 0;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gotham Medium';
  font-size: 17px;
  @media screen and (max-width: 600px){
    font-size: 14px;
    padding: 10px 0;
  }
  .step-adesione {
    color: #AEAEAE;
    margin: 0 40px;
    @media screen and (max-width: 845px){
      margin: 0 20px;
    }
    @media screen and (max-width: 600px){
      margin: 0 10px;
      text-align: center;
    }
    &.active {
      color: #1F9DD9;
      .num-step {
        border:2px solid #1F9DD9;
      }
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .num-step {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border:2px solid #AEAEAE;
      text-align: center;
      line-height: 33px;
      margin-right: 5px;
      @media screen and (max-width: 600px){
        margin-right: 0px;
        margin-bottom: 5px;
        width: 25px;
        height: 25px;
        line-height: 23px;
      }
    }
  }
  .sep-step {
    margin: 0 40px;
    color: #AEAEAE;
    @media screen and (max-width: 845px){
      margin: 0 20px;
    }
    @media screen and (max-width: 600px){
      margin: 0 10px;
    }
    &.active {
      color: #1F9DD9;
    }
  }
}
.adesione-form-container{
  width: 100%;
  padding: 20px 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (max-width: 980px){
    flex-wrap: wrap;
    flex-direction: column;
  }
  .adesione-icon-container {
    width: 365px;
    background-color: #fff;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    text-align: center;
    padding: 85px 25px;
    font-family: 'Gotham Medium';
    font-size: 25px;
    color: #1F9DD9;
    span.desc{
      display: block;
      font-size: 15px;
      font-family: 'Gotham Book';
      line-height: 1.4em;
      padding-top: 20px;
      color: #666;
    }
    &.thanks {
      width: 100%;
      background: none;
      padding-top: 25px;
      font-size: 35px;
      span.desc{
        font-size: 30px;
        color: #333;
      }
    }
    @media screen and (max-width: 1280px){
      width: 265px;
      font-size: 22px;
      &.thanks{
        font-size: 30px;
        span.desc{
          font-size: 25px;
        }
      }
    }
    img {
      max-width: 60%;
      display: block;
      margin:0 auto 35px;
    }
    @media screen and (max-width: 980px){
      width: 100%;
      padding: 25px 25px;
      border-radius: 30px;
      font-size: 18px;
      img {
        max-width: 80px;
        display: inline-block;
        vertical-align: middle;
        margin:0 35px 0 0;
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
      &.thanks{
        font-size: 25px;
        span.desc{
          font-size: 18px;
        }
        img {
          max-width: 120px;
          display: block;
          margin:0 auto 35px;
        }
      }
    }
    @media screen and (max-width: 600px){
      img {
          display: block;
          margin:0 auto 15px;
        }
    }
  }
  .adesione-modulo-container {
    width: calc(100% - 365px);
    padding: 42px 0;
    @media screen and (max-width: 1280px){
      width: calc(100% - 265px);
    }
    @media screen and (max-width: 980px){
      width: 100%;
      padding: 42px 0;
    }
    .modulo-btn{
      text-align: right;
    }
    .modulo-text{
      width: calc(100% -40px);
      padding: 20px 0 20px 0px;
      margin-left: 30px;
      border-top: 1px solid #cecece;
      @media screen and (max-width: 600px){
        width: 100%;
        margin-left: 0px;
      }
    }
    .modulo-row{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      &.bigger{
        margin-bottom: 50px;
      }
      &.start {
        align-items: flex-start;
        .modulo-label{
          padding-top: 15px;
        }
      }
      .modulo-label{
        width: 19%;
        text-align: right;
        font-family: 'Gotham Medium';
        font-size: 17px;
        padding-left: 10px;
        span {
          display: inline-block;
          font-size: 15px;
          font-family: 'Gotham Book';
          line-height: 1.2em;
          color: #666;
        }
      }
      .modulo-field{
        width: 73%;
        overflow: hidden;
        position: relative;
        &.flexy {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        input[type=text]{
          width: 100%;
          height: 60px;
          padding: 10px;
          background-color: #fff;
          border:1px solid #B5B5B5;
          border-radius: 9px;
          font-family: 'Gotham Book';
          font-size: 17px;
        }
        .auto-citta {
          &.MuiAutocomplete-root {
            width: 100%!important;
            min-height: 60px;
            padding: 10px;
            background-color: #fff;
            border:1px solid #B5B5B5;
            border-radius: 9px;
          }
          .MuiAutocomplete-popupIndicator{
            display: none!important;
          }
          div, input {
            box-sizing: border-box!important;
          }
          input{
            height: 40px;
            width: 0px;
            padding:0px;
            background-color: transparent;
            border:none;
            border-radius: 0px;
          }
          @media screen and (max-width: 600px){
            &.MuiAutocomplete-root {
              min-height: 40px;
              padding: 5px;
            }
            input {
              height: 30px!important;
              padding:0px!important;
            }
          }
        }
        .fake-select{
          position: absolute;
          display:flex;
          align-items: center;
          left: 0;
          top: 0;
          width: 100%;
          height: 60px;
          padding: 10px;
          background-color: #fff;
          border:1px solid #B5B5B5;
          border-radius: 9px;
          font-family: 'Gotham Book';
          font-size: 17px;
          z-index: 1;
          &:before {
            content: "";
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 14px 10px 0 10px;
            border-color: #1f9ed9 transparent transparent transparent;
          }
        }
        select{
          width: 120%;
          height: 60px;
          padding: 10px;
          cursor: pointer;
          background: transparent;
          border:none;
          border-radius: 9px;
          font-family: 'Gotham Book';
          font-size: 17px;
          opacity: 0;
          position: relative;
          z-index: 2;
        }
        textarea{
          width: 100%;
          height: 120px;
          padding: 10px;
          background-color: #fff;
          border:1px solid #B5B5B5;
          border-radius: 9px;
          font-family: 'Gotham Book';
          font-size: 17px;
        }
        @media screen and (max-width: 600px){
          input[type=text], select, .fake-select {
            height: 40px;
            padding: 5px;
          }
          textarea {
            height: 80px;
            padding: 5px;
          }
        }
      }
    }
    &.step2 {
      .modulo-label{
        width: 37%;
      }
      .modulo-field{
        width: 60%;
      }
    }
    &.step3 {
      .modulo-label{
        width: 45%;
      }
      .modulo-field{
        width: 50%;
      }
    }
    @media screen and (max-width: 600px){
      .modulo-row {
        flex-wrap: wrap;
      }
      .modulo-label{
        width: 100%!important;
        padding-bottom: 5px;
        padding-left: 0px!important;
        padding-top: 0px!important;
        font-size: 15px!important;
        text-align: left!important;
        span{
          font-size: 13px!important;
        }
      }
      .modulo-field{
        width: 100%!important;
      }
    }
  }
}
.modulo-radio {
  width: 40%;
  @media screen and (max-width: 600px){
    width: 20%;
  }
  label {
    margin:0px;
    cursor: pointer;
    position: relative;
    span {
      display: inline-block;
      vertical-align: top;
      max-width: calc(100% - 40px);
    }
    .circle {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      max-width: none;
      border-radius: 50%;
      background-color: #fff;
      border:1px solid #b5b5b5;
      z-index: 1;
      position: relative;
      &.chck{
        border-radius: 4px;
      }
    }
  }
  &.full {
    width: 100%;
    label {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  input[type=radio], input[type=checkbox] {
    display: none;
    &:checked + label {
      .circle {
        border:1px solid #1F9DD9;
        z-index: 1;
        &:after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #1F9DD9;
          left: 6px;
          top: 50%;
          transform: translate(0%, -50%);
          z-index: 1;
        }
        &.chck{
          &:after {
            border-radius: 2px;
          }
        }
      }
    } 
  }
}

.name-shop {
  width: 100%;
  background: #009fe2;
  padding: 10px 0;
  position: relative;
  .link-back {
    position: absolute;
    right:32px;
    top:0px;
    display: inline-block;
    font-family: "Gotham Bold";
    font-size: 1em;
    color: #fff;
    text-align: left;
    @media screen and (max-width: 767px) {
      position: static;
      right:0px;
      display: block;
      text-align: right;
    }
  }
  h1 {
    margin: 0px 0px 10px;
    padding: 0px;
    font-family: "Gotham Bold";
    font-size: 2.19em;
    color: #fff;
    text-align: left;
    @media screen and (max-width: 1100px){
      font-size: 1.8em;
    }
    @media screen and (max-width: 767px){
      font-size: 1.4em;
    }
  }
  h2 {
    margin: 0px;
    padding: 0px;
    font-family: "Gotham Medium";
    font-size: 1.25em;
    color: #fff;
    text-align: left;
    @media screen and (max-width: 767px){
      font-size: 1em;
    }
  }
}
.name-shop-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .name-shop-desc {
    width: 60%;
    @media screen and (max-width: 800px){
      width: 50%;
    }
    @media screen and (max-width: 640px){
      width: 100%;
    }
  }
  .name-shop-link {
    width: 40%;
    text-align: right;
    @media screen and (max-width: 800px){
      width: 50%;
    }
    @media screen and (max-width: 640px){
      margin-top:10px;
      width: 100%;
    }
  }
}
.name-shop-url {
  display: inline-block;
  background-color: #fbca00;
  padding: 8px 30px;
  border-radius: 27px;
  color: #fff;
  cursor: pointer;
  font-family: 'Gotham Medium';
  font-size: 20px;
  text-transform: uppercase;
  margin-left: 5px;
  &:hover{
    background-color: #f6f6f6;
    color: #000;
  }
  &.phone{
    background-color: #39DD58;
    color: #fff;
  }
  &.phone:hover{
    background-color: #fbca00;
    color: #fff;
  }
  @media screen and (max-width: 1100px){
    padding: 7px 25px;
    border-radius: 23px;
    font-size: 17px;
  }
  @media screen and (max-width: 767px){
    padding: 6px 20px 3px;
    border-radius: 20px;
    font-size: 14px;
  }
  &.under{
    @media screen and (max-width: 767px){
      padding: 6px 10px 3px;
      border-radius: 20px;
      font-size: 12px;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 800px){
    img { height:20px; }
  }
}


.shop-page {
  width: 100%;
  min-height: 10vh;
  background: #f6f6f6;
  .slick-track {
    margin-left:0;
    margin-right:0;
  }
  .slick-prev {
    left: -33px !important;
    top: 30%;
    z-index: 50;
    width: 67px;
    height: 50px;
  }
  .slick-next {
    right: -33px !important;
    top: 30%;
    z-index: 50;
    width: 67px;
    height: 50px;
    transform: translate(0, -50%) rotate(180deg) !important;
  }
  @media screen and (max-width: 980px){
    .slick-prev {
      left: -20px !important;
      top: 30%;
      width: 40px;
      height: 30px;
    }
    .slick-next {
      right: -20px !important;
      top: 30%;
      width: 40px;
      height: 30px;
      transform: translate(0, -50%) rotate(180deg) !important;
    }
  }
  @media screen and (max-width: 640px){
    .slick-prev {
      left: -45px !important;
      top: 30%;
      width: 40px;
      height: 30px;
    }
    .slick-next {
      right: -45px !important;
      top: 30%;
      width: 40px;
      height: 30px;
      transform: translate(0, -50%) rotate(180deg) !important;
    }
  }
  /* the slides */
  .slick-slide > div {
    margin: 0 15px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -15px;
  }
  .shop-name{
    height:40px;
    font-size: 1.1em;
  }
  .shop-card .shop-card-media{
    height:138px;
  }
}
.shop-info {
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
  background-color: #fff;
  ul {
    margin: 0px;
    padding: 0px;
    text-align: center;
    li {
      display: inline-block;
      cursor: pointer;
      list-style: none;
      padding: 10px 75px 6px;
      border-bottom: 4px solid #fff;
      text-transform: uppercase;
      &:last-child {
        margin-right: 0px;
      }
      &:hover,
      &.active {
        font-family: "Gotham Medium";
        border-bottom: 4px solid #009fe2;
      }
      @media screen and (max-width: 959px) {
        padding: 10px 35px 6px;
      }
      @media screen and (max-width: 459px) {
        padding: 10px 15px 6px;
      }
    }
  }
}
.shop-menu {
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
  background-color: #fff;
  ul {
    margin: 0px;
    padding: 0px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    li {
      display: inline-block;
      width: 33.333%;
      cursor: pointer;
      list-style: none;
      text-align: center;
      padding: 10px 0px 6px;
      border-bottom: 4px solid #fff;
      text-transform: uppercase;
      &:last-child {
        margin-right: 0px;
      }
      &:hover,
      &.active {
        font-family: "Gotham Medium";
        border-bottom: 4px solid #009fe2;
      }
    }
  }
}

.shop-submenu-container {
  padding-bottom:20px;
  background-color: #f6f6f6;
  position: sticky;
  top:0px;
}
.shop-submenu {
  width: 100%;
  padding-top:20px;
  height: 55px;
  overflow: hidden;
  @media screen and (max-width: 599px) {
    height: 52px;
  }
  &.show-all {
    height: auto;
  }
  .altro{
    display: inline-block;
    vertical-align: top;
    width: 15%;
    text-align: right;
    @media screen and (max-width: 599px) {
      width: 30%;
    }
    span {
      cursor: pointer;
      display: inline-block;
      font-family: 'Gotham Medium';
      font-size: 0.9em;
      padding: 7px 10px 5px 0px;
      margin-bottom: 5px;
    }
    svg {
      cursor: pointer;
    }
  }
  ul {
    margin: 0px;
    padding: 0px;
    display: inline-block;
    vertical-align: top;
    width: 85%;
    @media screen and (max-width: 599px) {
      width: 70%;
    }
    li {
      display: inline-block;
      cursor: pointer;
      list-style: none;
      padding: 7px 15px 5px;
      text-transform: uppercase;
      margin-right: 10px;
      margin-bottom: 5px;
      font-size: 0.9em;
      &:last-child {
        margin-right: 0px;
      }
      &:hover,
      &.active {
        background: #009fe2;
        color: #fff;
        border-radius: 19px;
      }
      @media screen and (max-width: 459px) {
        padding: 5px 10px 3px;
      }
    }
  }
}

.prod-info{
  display: inline-block;
  margin-bottom: 10px;
}
.tab-scheda {
  width: 100%;
  padding: 43px 0;
  &.condizioni {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .condizioni-container{
    max-width: 49%;
    &.con-listino{
      max-width: none;
      width: 100%;
    }
    @media screen and (max-width: 1200px) {
      max-width: none;
      width: 100%;
    }
  }
  .condizioni-title{
    padding: 10px 40px;
    color: #fff;
    margin-bottom:50px;
    font-size: 20px;
    background: #009fe2;
    width: 100%;
    @media screen and (max-width: 767px) {
      padding: 10px 20px;
      font-size: 17px;
    }
    img, span {
      display: inline-block;
      vertical-align: middle;
      margin-right:20px;
    }
  }
  .tab-request{
    padding: 20px 0 0 0px;
    text-align: center;
    a{
      display: inline-block;
      padding: 15px 40px;
      border-radius: 20px;
      background-color: #009fe2;
      color: #fff;
      &:hover{
        background-color: #fbca00;
        color: #000;
      }
    }
  }
  .tab-scheda-tit{
    display: inline-block;
    padding:0px;
    margin-bottom: 7px;
    font-size: 20px;
    font-family: "Gotham Bold";
    &.nomarg {
      margin:0px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  p {
    margin: 0 0 25px;
  }
  .tab-scheda-masonry{
    padding: 20px 0;
    img{
      cursor: pointer;
    }
  }

  .tab-scheda-img-container{
    width: calc(100% - 80px);
    padding: 20px 0 40px;
    margin: auto;
    .slick-list {
      height: 300px;
      div {
        height: 100%;
      }
      .slick-slide {
        padding:0 10px;
        text-align: center;
      }
      img {
        @media screen and (min-width: 601px) {
          height: 100%;
          width: auto!important;
          object-fit: contain;
        }
        transform: scale(1);
        cursor: zoom-in;
        transition: transform 0.3s ease;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .slick-next {
      width: 40px;
      right: -50px;
      transform: translate(0, -50%) rotate(180deg) !important;
      @media screen and (max-width: 600px) {
        width: 20px;
        right: -30px;
      }
    }
    .slick-prev {
      width: 40px;
      left: -50px;
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      margin: 0px;
      padding: 20px 0 20px;
    }
  }
  .tab-scheda-phone{
    width: 100%;
    padding: 30px 0px;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .tab-scheda-num {
      width: 50%;
      text-align: center;
      font-size: 1.2em;
      strong{
        font-family: "Gotham Medium";
      }
    }
    &:not(.only-one){
        .tab-scheda-num:last-child{
        border-left:1px solid #333;
      }
    }
  }
  .tab-scheda-canali{
    width: 100%;
    padding: 30px 0px;
    text-align: center;
    .tab-scheda-url-title {
      display: inline-block;
      vertical-align: middle;
      margin:10px 20px;
    }
    .tab-scheda-url {
      display: inline-block;
      vertical-align: middle;
      margin:10px 20px;
      @media screen and (max-width: 767px) {
        margin:10px 10px;
        img { height: 26px!important; }
      }
    }
  }
  iframe, .leaflet-container{
    width: 100%!important;
    height: 465px!important;
    @media screen and (max-width: 1100px) {
      height: 365px!important;
    }
    @media screen and (max-width: 767px) {
      height: 265px!important;
    }
  }
  .tab-scheda-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom:1px solid #e3e3e3;
    &:last-child {
      border-bottom: none;
    }
    p{ margin:0px!important }
    .tab-scheda-icon {
      width: 70px;
    }
    .tab-scheda-info {
      width: calc(100% - 70px);
    }
  }
}
.fascia-consegna {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  height: 70px;
  background: #e8e8e8;
}
.shop-products {
  width: 100%;
  padding-bottom: 50px;
}
.product-name {
  padding: 0px;
  margin: 0px 0 10px;
  color: #000;
  display: inline-block;
  font-family: "Gotham Bold";
  font-size: 1.25em;
  text-align: left;
  @media screen and (max-width: 767px) {
    font-size: 1em;
  }
}
.specification {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  &:first-child {
    margin-left: 20px;
  }
}
.allergen {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 15px 0px 0;
  &:last-child {
    margin-right: 0px;
  }
  @media screen and (max-width: 767px) {
    margin: 10px 10px 5px 0;
  }
}
.product-ingredients {
  color: #666;
}
.actions-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding:20px 15px 10px;
  /* background-color: #D5ECF6; */
  color: #009fe2;
}
.actions-all {
  width: 40%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.actions-act {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.actions-block {
  font-size: 1.125em;
  font-family: 'Gotham Bold';
  margin-right:10px;
  color: #009fe2;
  @media screen and (max-width: 767px) {
    margin-right:5px;
    font-size: 1em;
  }
  @media screen and (max-width: 359px) {
    font-size: 0.9em;
  }
  &:last-child {
    margin-right:0px;
  }
  .actions-btn{
    font-size: 0.9em;
    color: #009fe2;
    cursor: pointer;
    img {
      display: inline-block;
      vertical-align: middle;
      margin-right:5px;
    }
    &:hover{
      color:#000;
    }
  }
  .actions-btn-full{
    display: inline-block;
    padding: 6px 25px 3px;
    border-radius: 23px;
    margin:0 5px;
    background-color: #1F9DD9;
    color: #fff;
    cursor: pointer;
    font-family: 'Gotham Medium';
    svg {
      display: inline-block;
      vertical-align: middle;
      margin-right:5px;
    }
    &:hover{
      background-color: #fbca00;
      color: #333;
      svg .a{
        fill:#333;
      }
    }
    @media screen and (max-width: 500px) {
      margin:0 1px;
      padding: 6px 17px;
    }
  }
}

.product-card-container {
  width: 100%!important;
  margin-top: 20px;
  &:first-child{
    margin-top: 0px;
  }
  .product-card {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .product-card-text{
    width: 72%;
    padding-bottom: 0px!important;
    &.no-foto {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      padding:15px!important;
      padding-bottom: 0px!important;
    }
  }
  .img-piatto{
    width: 28%;
    margin: 10px 0;
    background-color: #fff;
    background-size: contain;
    cursor: zoom-in;
    min-height: 120px;
    @media screen and (max-width: 600px) {
      min-height: 80px;
    }
    &.no-foto {
      min-height: 0px;
      margin: 0px;
      background-color: #C4E0E3 ;
      background-size: contain;
    }
  }
}
@media screen and (max-width: 1179px) {
  .MuiCard-root.product-card-container {
    width: 100% !important;
    margin: 20px 0 0 0;
    &:first-child{
      margin-top: 0px;
    }
  }
}

.product-grid {
  padding-right: 30px;
}
@media screen and (max-width: 959px) {
  .product-grid {
    padding-right: 0px;
  }
}

.product-cart {
  position: relative;
  top: -60px;
  border-radius: 20px !important;
  padding: 0px !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #fbca00;
  overflow: hidden;
}
.product-cart.in-cart{
  top: 0px;
}
@media screen and (max-width: 1179px) {
  .product-cart {
    top: 0px;
    margin: 20px 0 0 0;
  }
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
}

.cart-title {
  font-family: "Gotham Bold";
  font-size: 1.375em;
  text-align: center;
  display: block;
  padding: 18px;
  line-height: 1em;
  background: #fbca00;
}

.cart-container {
  /* box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1) inset; */
  padding-top: 10px;
  padding-bottom: 20px;
}
.shop-first-slot-container{
  background-color: #e9e9e9;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shop-first-slot{
  width: 50%;
  text-align: center;
  margin:8px 0;
  font-size: 13px;
  &:first-child{
    border-right:1px solid #b7b3b3;
  }
  &:last-child{
    border-right:none;
  }
}
.cart-row {
  padding: 15px 0;
  border-bottom: 1px solid #e3e3e3;
}
.cart-row.total {
  border-bottom: 0px;
  padding-bottom: 50px;
}
.product-cart.in-cart .cart-row.total{
  padding-bottom: 0px;
}
.main-item {
  font-family: "Gotham Bold";
  display: block;
}
.ingredients {
  font-size: 0.8em;
  color: #666;
  display: block;
  /* margin-top: 10px; */
}

.circle-btn {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid #fbca00;
  text-align: center;
  color: #fbca00;
  line-height: 18px;
  cursor: pointer;
  position: relative;
  top: -1px;
}
.quantity {
  font-size: 1.125em;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
  text-align: center;
  @media screen and ( max-width: 767px ) {
    width: 14px;
  }
}

.MuiDialog-paperWidthSm {
  width: 600px;
  border-radius: 20px !important;
}
.MuiBackdrop-root {
  background-color: rgba(251, 202, 0, 0.8) !important;
}
#scroll-dialog-title {
  h2 {
    padding:0!important;
  }
}
.dialog-title {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #009fe2;
  font-family: "Gotham Medium";
  font-size: 1.25em;
  margin-bottom: 35px;
}
.dialog-product-image {
  text-align: center;
}
.dialog-product-image img {
  max-width: 50%;
}
.dialog-product-name {
  text-align: center;
  font-size: 1em;
  line-height: 1.2em;
  padding: 0px 30px;
  margin: 10px 0;
}
.dialog-product-name span {
  font-size: 0.88em;
  font-family: "Gotham Book";
}
@media screen and ( max-height: 1100px ) {
  .dialog-product-image img {
    max-width: 30%;
  }
}
@media screen and (max-width: 767px), screen and ( max-height: 900px ) {
  .dialog-title {
    font-size: 1em;
    margin-bottom: 15px;
  }
  .dialog-product-image {
    display: none;
  }
  .dialog-product-name {
    font-size: 0.9em;
  }
}

.dialog-content {
  padding: 8px 0px !important;
}
.dialog-subtitle {
  background: #e9e9e9;
  padding: 5px 20px;
  font-family: "Gotham Medium";
  font-size: 1.25em;
  @media screen and (max-width: 767px), screen and (max-height: 900px){
    font-size: 1em;
  }
}
.dialog-ingredients {
  padding: 15px 20px 30px;
  font-family: "Gotham Book";
  font-size: 1.125em;
  @media screen and (max-width: 767px), screen and (max-height: 900px){
    font-size: 1em;
  }
  .dialog-ingredient-name{
    width: calc(100% - 30px);
  }
}
.dialog-row-ingredient {
  width: 100%;
  position: relative;
  padding: 10px 0px;
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 767px), screen and (max-height: 900px){
    padding: 7px 0px;
  }
  &:last-child{
    border-bottom: none;
  }
  .remove {
    display: inline;
  }
  .addagain {
    display: none;
  }
}
.dialog-row-ingredient.removed {
  border-bottom: 1px solid #eee;
  &:last-child{
    border-bottom: none;
  }
  .remove {
    display: none;
  }
  .addagain {
    display: inline;
  }
}
.dialog-row-ingredient.removed .dialog-ingredient-name {
  opacity: 0.3;
}
.dialog-ingredient-remove {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 30px;
}
@media screen and (max-width: 767px), screen and (max-height: 900px){
  .dialog-ingredient-remove {
    width: 20px;
  }
}

.dialog-ingredient-add {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background: #fff;
  border: 2px solid #fbca00;
  line-height: 28px;
  position: relative;
  cursor: pointer;
}
.dialog-ingredient-add svg {
  max-width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px), screen and (max-height: 900px){
  .dialog-ingredient-add {
    width: 20px;
    height: 20px;
  }
  .MuiDialog-paper {
    margin: 18px !important;
  }
}

.dialog-ingredient-add.active {
  background: #fbca00;
}
.dialog-ingredient-price {
  color: #00a0df;
  @media screen and (max-width: 767px), screen and (max-height: 900px){
    font-size:0.8em;
  }
}
.dialog-note {
  padding: 12px;
  margin:0 20px 20px;
  border: 1px solid #acacac;
  border-radius: 10px;
}
.note-title {
  font-family: "Gotham Medium";
}
.note-title em {
  font-style: normal;
  color: #666;
}
.dialog-note textarea, .cart-info-container textarea {
  width: 100%;
  height: 140px;
  resize: none;
  outline: none;
  border: none;
  background: none;
  font-family: "Gotham Book";
  color: #666;
  font-size: 0.8em;
  @media screen and (max-width: 767px), screen and (max-height: 900px){
    height: 90px;
  }
}
.cart-info-container textarea {
  border:1px solid #ccc;
  padding:15px;
  color: #333;
}
.dialog-actions-container{
  @media screen and (max-width: 767px), screen and (max-height: 900px){
    padding:20px!important;
  }
}
.dialog-actions {
  width: 100%;
  text-align: center;
  font-family: "Gotham Bold";
  font-size: 1.56em;
  @media screen and (max-width: 767px), screen and (max-height: 900px){
    font-size: 1.2em;
  }
}
@media screen and (max-width: 767px), screen and (max-height: 900px){
  .add-or-remove {
    width: 30px;
  }
}

.dialog-confirm {
  width:90%;
  margin-left: 10%;
  border-radius: 35px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 10px 25px;
  display: block;
  background: #00a0df;
  color: #fff;
  cursor: pointer;
  font-size: 1.15em;
  text-align: center;
}
.dialog-confirm:hover {
  background: #fbca00;
  color: #000;
}
@media screen and (max-width: 767px), screen and (max-height: 900px){
  .dialog-confirm {
    width:95%;
    margin-left: 5%;
    font-size: 0.85em;
    padding: 10px 5px;
  }
}

.deactivate{
  opacity:0.5;
}
.no-products{
  padding:40px 0 25px;
  text-align: center;
}

.only-zoom{
  /* .MuiPaper-root{
    background: none;
    box-shadow: none;
  } */
  .MuiDialog-paperWidthSm{
    width: auto;
    max-width: none;
  }
}
.zoom-product-name {
  margin:0px;
}
#scroll-dialog-title {
  padding: 24px!important;
  @media screen and (max-width: 767px), screen and (max-height: 900px){
    padding: 12px!important;
  }
}
.zoom-img {
  height: 100%;
  padding: 0 24px 24px!important;
  position: relative;
  @media screen and (max-width: 767px), screen and (max-height: 900px){
    padding: 0 12px 12px!important;
  }
  .arrowNext {
    width: 40px;
    position: absolute;
    top: 50%;
    right: 10px;
    cursor: pointer;
    transform: translate(0, -50%) rotate(180deg) !important;
  }
  .arrowPrev {
    width: 40px;
    position: absolute;
    top: 50%;
    left: 10px;
    cursor: pointer;
    transform: translateY(-50%);
  }
  img {
    max-height: 70vh;
    object-fit: contain;
    @media screen and (max-height: 500px){
      max-height: 50vh;
    }
  }
}

.lightbox-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s;
  &.closed{
      top: -100vh;
  }
  &.opened{
      top:0;
      opacity: 1;
  }
  .lightbox-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(251,202,0,0.95)!important;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .lightbox{
    width: 100%;
    max-width: 1200px;
    height: 70vh;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 600px) {
      height: 60vh;
    }
    div {
      height: 100%;
    }
    .lightbox-img{
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .slick-list{
      margin: 0 auto;
      max-width: 80%;
    }
    .slick-next {
      width: 20px;
      transform: translate(0, -50%) rotate(180deg) !important;
      z-index: 10;
      right: 25px;
      @media screen and (max-width: 600px) {
        width: 10px;
        right: 10px;
      }
    }
    .slick-prev {
      width: 20px;
      z-index: 10;
      left: 25px;
      @media screen and (max-width: 600px) {
        width: 10px;
        left: 10px;
      }
    }
    .slick-dots {
      bottom: -50px;
    }
    .slick-dots li, .slick-dots li button{
      width: 20px;
      height: 20px;
      @media screen and (max-width: 1200px) {
        width: 10px;
        height: 10px;
      }
    }
    .slick-dots li button:before{
      color: #fff;
      font-size: 16px;
      width: 20px;
      height: 20px;
      opacity: 0.75;
      @media screen and (max-width: 1200px) {
        width: 10px;
        height: 10px;
        font-size: 11px;
      }
      @media screen and (max-width: 600px) {
        font-size: 6px;
      }
    }
    .slick-dots li.slick-active button:before{
      color: #1f9ed9;
    }
  }
  .lightbox-close{
    position: absolute;
    top: 30px;
    right:  30px;
    z-index: 5;
    background: #1f9ed9;
    color: #000;
    width: 37px;
    height: 37px;
    text-align: center;
    line-height: 34px;
    border-radius: 10px;
    cursor: pointer;
    @media screen and (max-width:767px){
      border-radius: 5px;
      top: 20px;
      right: 20px;
      width: 27px;
      height: 27px;
      text-align: center;
      line-height: 24px;
      img{
          width: 15px;
      }
    }
  }
}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.my-masonry-grid_column > img { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 15px;
}
/* Optional, different gutter size on mobile */
@media (max-width: 800px) {
  .my-masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 15px; /* gutter size offset */
  }
  .my-masonry-grid_column > div {
    margin-bottom: 15px; /* space between items */
  }
}