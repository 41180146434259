.prefooter{
  width: 100%;
  padding: 40px 15px;
  background: #FCCA00;
  color: #333;
  .share{
    border-left:1px solid #fff;
    @media screen and (max-width: 960px){
      border-left:0px;
    }
    .share-icon {
      width:40px;
      height: 40px;
      text-align: center;
      line-height: 36px;
      background-color: #000;
      display: inline-block;
      vertical-align: middle;
      margin:0 10px;
      &.fb { background-color: #4267B2; }
      &.tw { background-color: #1A91DA; }
      &.wap { background-color: #00E676; }
      img{
        margin:0!important;
      }
    }
  }
  .prefooter-text{
    .prefooter-title{
      font-size: 1.4em;
      font-family: 'Gotham Medium';
      display: block;
      text-align: center;
      margin-bottom: 30px;
      @media screen and (max-width: 960px){
        margin-bottom: 10px;
      }
      @media screen and (max-width: 500px){
        font-size: 1.2em;
      }
    }
    img {
      display: inline-block;
      vertical-align: middle;
      margin:0 20px;
      @media screen and (max-width: 960px){
        margin:0 5px 20px 5px;
      }
    }
  }
}
.follow{
  width: 100%;
  padding: 20px 0px;
  /* margin-top: 40px; */
  border-top:1px solid #ffffff30;
  border-bottom:1px solid #ffffff30;
  text-align: center;
  strong{
    font-family: 'Gotham Medium';
    display: inline-block;
    vertical-align: middle;
    margin:0 25px;
    @media screen and (max-width: 768px){
      margin:0 15px;
    }
    @media screen and (max-width: 480px){
      margin:0 0 10px;
      display: block;
    }
  }
  a{
    display: inline-block;
    vertical-align: middle;
    margin:0 25px;
    @media screen and (max-width: 768px){
      margin:0 5px;
    }
  }
  span, svg {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    margin-right: 15px;
    @media screen and (max-width: 768px){
      margin-right: 0px;
      width: 18px!important;
    }
  }
}
.footer-cols{
  width: 100%;
  max-width: 1200px;
  padding: 20px 0px;
  border-bottom:1px solid #ffffff30;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 767px){
    padding: 0px 0px;
  }
  .footer-col{
    width: 25%;
    padding-left: 8%;
    @media screen and (max-width: 1200px){
      padding-left: 4%;
    }
    @media screen and (max-width: 767px){
      width: 50%;
      margin-bottom: 20px;
    }
    strong {
      font-size: 16px;
      font-family: 'Gotham Medium';
      display: block;
      margin-bottom: 30px;
      @media screen and (max-width: 767px){
        margin-bottom: 15px;
        font-size: 13px;
      }
    }
    a {
      font-size: 15px;
      display: block;
      margin-bottom: 20px;
      @media screen and (max-width: 767px){
        margin-bottom: 5px;
        font-size: 13px;
      }
      span, svg {
        display: inline-block;
        vertical-align: middle;
      }
      span {
        margin-left:10px;
      }
    }
  }
}
footer {
  width: 100%;
  padding: 30px 15px 0px;
  background: #009fe2;
  color: #fff;
  a{
    color: #fff!important;
    text-decoration: none!important;
  }
  .MuiGrid-spacing-xs-10 > .MuiGrid-item {
      padding: 10px 30px;
      text-align: center;
  }
}
.footer-text {
  text-align: left;
  color: #fff;
  font-size:0.9em;
  line-height: 1.8em;
  strong {
    font-size:1.1em;
  }
  @media screen and (max-width: 1200px){
      font-size: 0.8em;
      text-align: center;
      line-height: 1.5em;
      padding: 10px;
  }
  @media screen and (max-width: 768px){
      font-size: 0.75em;
      text-align: center;
      line-height: 1.5em;
      .altre-voci { display:none; }
  }
}
.footer-text a{
  color: #fff;
  text-decoration: none;
}
.footer-text span, .footer-text svg{
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.footer-app {
  text-align: right;
}
.copy {
  width:100%;
  text-align: center;
  padding: 30px 0;
  strong {
    font-family: 'Gotham Bold';
  }
  .sep {
    display: inline-block;
    vertical-align: middle;
    margin:0 10px;
  }
  a{
    color: #fff!important;
    text-decoration: none!important;
  }
  img{
    display: inline-block;
    max-width: 100%;
    background: #fff;
  }
  @media screen and (max-width: 768px){
    font-size: 0.8em;
  }
}
footer ul {
  padding:0px;
  margin:0px;
  list-style: none;
}
footer ul li{
  margin-bottom:32px;
}

