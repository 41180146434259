@import-normalize; /* bring in normalize.css styles */
body {
  margin: 0;
  font-family: 'Gotham Book', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
strong {
  font-family: 'Gotham Bold';
}

@media screen and (max-width: 768px){
  body { font-size: 0.9em; }
}

@media screen and (max-width: 1279px){
  br:not(.mob){
    display: none;
  }
}

.location-name {
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color:#000!important;
    opacity:1!important;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:#000!important;
    opacity:1!important;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:#000!important;
    opacity:1!important;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:#000!important;
    opacity:1!important;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color:#000!important;
    opacity:1!important;
  }

  input::placeholder { /* Most modern browsers support this now. */
    color:#000!important;
    opacity:1!important;
  }
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a, a:link, a:visited, a:hover{
  color:#000;
  text-decoration: none;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('assets/fonts/Gotham-Book.woff2') format('woff2'),
        url('assets/fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Black';
    src: url('assets/fonts/Gotham-Black.woff2') format('woff2'),
        url('assets/fonts/Gotham-Black.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Bold';
    src: url('assets/fonts/Gotham-Bold.woff2') format('woff2'),
        url('assets/fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Medium';
    src: url('assets/fonts/Gotham-Medium.woff2') format('woff2'),
        url('assets/fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

h2 {
  font-family: 'Gotham Medium';
  font-size: 2.8em;
  color: #000;
  padding:65px 0;
  margin:0px;
  text-align: center;
  @media screen and (max-width: 1200px){
    font-size: 2.2em;
    padding:50px 0;
  }
  @media screen and (max-width: 768px){
    font-size: 1.8em;
    padding:35px 0;
  }
  @media screen and (max-width: 520px){
    font-size: 1.6em;
    padding:25px 0;
  }
}
h4 {
  font-size: 1.875em;
  color: #000;
  padding:0px 0;
  margin:0px 0 30px;
  text-align: center;
}
@media screen and (max-width: 768px){
  h4 {
    font-size: 1.3em;
  }
}

.onda{
  position: absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: auto;
  z-index: 0;
}
.top-container{
  width:100%;
  padding-top:75px;
  position: relative;
  background-color: #009fe2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 50px;
  @media screen and (max-width: 768px){
    padding-top:50px;
    padding-bottom: 25px;
  }
  h1 {
    font-family: 'Gotham Bold';
    font-size: 2.5em;
    color: #fff;
    margin:0px auto 20px;
    padding: 0px;
    max-width: 1000px;
    text-align: center;
    @media screen and (max-width: 1100px){
      font-size: 2.2em;
    }
    @media screen and (max-width: 768px){
      font-size: 1.8em;
      padding: 25px 0 0 0;
    }
    @media screen and (max-width: 520px){
      font-size: 1.8em;
      padding: 0px 0 0 0;
    }
  }
}
.faq-text{
  font-size: 15px!important;
  line-height: 1.7em!important;
}

@media screen and (max-width: 850px){
  .app-icons{
    width:130px;
  }
}

#alert-dialog-title{
  text-align: center !important;
}
.alert-dialog-title {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #009fe2;
  font-family: "Gotham Medium";
  font-size: 1.25em;
}
.dialog-alert-content{
  text-align:center;
  font-size:1.25em;
  font-family:'Gotham Medium';
  padding-top:25px !important;
  padding-bottom:25px !important;
}

.generic {
  width: 100%;
  padding:0 0px 50px;
  h3 {
    font-family: 'Gotham Medium';
    font-size: 2.8em;
    color: #000;
    padding:0;
    text-align: center;
    @media screen and (max-width: 1200px), screen and (max-height: 1000px) {
      font-size: 2.1em;
    }
    @media screen and (max-width: 768px){
      font-size: 1.8em;
    }
    @media screen and (max-width: 520px){
      font-size: 1.6em;
    }
  }
}
.generic-row {
  width: 100%;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  @media screen and (max-width: 768px){
    padding: 30px 0px;
  }
  .generic-half {
    width: 49%;
    @media screen and (max-width: 768px){
      width: 100%;
      padding: 15px 0;
    }
  }
}
.numeri {
  width: 100%;
  padding: 50px 15px;
  background: #009fe2;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  color: #fff;
  @media screen and (max-width: 1024px){
    padding: 30px 15px;
  }
  .numeri-text {
    width: 100%;
    max-width: 270px;
    margin: 0 50px;
    font-size: 20px;
    font-family: 'Gotham Medium';
    text-align: center;
    @media screen and (max-width: 1200px){
      max-width: 200px;
      margin: 0 35px;
    }
    @media screen and (max-width: 1024px){
      max-width: 180px;
      margin: 0 25px;
      font-size: 18px;
    }
    @media screen and (max-width: 767px){
      margin: 5px;
    }
    h3 {
      font-family: 'Gotham Medium';
      font-size: 2.8em;
      padding:0;
      color: #fff;
      @media screen and (max-width: 1200px), screen and (max-height: 1000px) {
        font-size: 2.1em;
      }
      @media screen and (max-width: 768px){
        font-size: 1.8em;
      }
      @media screen and (max-width: 520px){
        font-size: 1.6em;
      }
    }
  }
}
.generic-info{
  text-align: center;
  img {
    display: block;
    margin:auto;
    max-width: 60%;
  }
  h4 {
    font-family: 'Gotham Bold';
    font-size: 22px;
    padding:0;
    margin:40px 0 20px;
    @media screen and (max-width: 1024px){
      font-size: 18px;
    }
  }
  p {
    font-size: 17px;
    color: #666;
    max-width: 250px;
    margin:0 auto;
    @media screen and (max-width: 768px){
      font-size: 15px;
    }
  }
}

.chisiamo {
  width: 100%;
  padding: 100px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  &.cnt {
    padding: 50px 15px;
    text-align: center;
  }
  &.full {
    padding: 0px 15px;
    text-align: center;
  }
  @media screen and (max-width: 1200px){
    padding: 30px 15px;
  }
  img {
    margin-right: 85px;
    &.offriamo {
      margin-right: 0px;
      margin-top: 60px;
    }
    @media screen and (max-width: 1200px){
      max-width: 300px;
      &.offriamo {
        max-width: 500px;
      }
    }
    @media screen and (max-width: 1023px){
      margin-right: 35px;
      max-width: 250px;
      &.offriamo {
        margin-right: 0px;
        max-width: 350px;
      }
    }
    @media screen and (max-width: 767px){
      margin:0 auto;
      max-width: 200px;
      &.offriamo {
        max-width: 100%;
      }
    }
  }
  .chisiamo-text {
    width: 100%;
    max-width: 600px;
    font-size: 17px;
    &.offriamo {
      max-width: 470px;
    }
    &.full {
      max-width: 100%!important;
    }
    @media screen and (max-width: 1200px){
      max-width: 500px;
      &.offriamo {
        max-width: 470px;
      }
    }
    @media screen and (max-width: 1023px){
      max-width: 450px;
      font-size: 15px;
      &.offriamo {
        max-width: 380px;
      }
    }
    @media screen and (max-width: 767px){
      max-width: 100%;
      font-size: 15px;
      &.offriamo {
        max-width: 100%;
      }
    }
    p {
      color: #666;
      margin: 0 0 15px;
    }
    h3 {
      font-family: 'Gotham Medium';
      font-size: 2.8em;
      color: #000;
      padding:0;
      text-align: left;
      @media screen and (max-width: 1200px), screen and (max-height: 1000px) {
        font-size: 2.1em;
      }
      @media screen and (max-width: 768px){
        margin-top: 30px;
        &.offriamo {
          margin-top: 0px;
        }
      }
      @media screen and (max-width: 768px){
        font-size: 1.8em;
      }
      @media screen and (max-width: 520px){
        font-size: 1.6em;
      }
    }
  }
}

.perche-rivemo-container {
  width: 100%;
  padding: 50px 15px;
  background: #eee;
  @media screen and (max-width: 1024px){
    padding: 30px 15px;
  }
  .perche-rivemo {
    width: 100%;
    max-width: 1200px;
    margin:auto;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    padding-bottom: 30px;
  }
  h3 {
    font-family: 'Gotham Medium';
    font-size: 2.8em;
    padding:0;
    text-align: center;
    @media screen and (max-width: 1200px), screen and (max-height: 1000px) {
      font-size: 2.1em;
    }
    @media screen and (max-width: 768px){
      font-size: 1.8em;
    }
    @media screen and (max-width: 520px){
      font-size: 1.6em;
    }
  }
  .perche-rivemo-text {
    width: 33.33%;
    font-size: 20px;
    font-family: 'Gotham Medium';
    text-align: center;
    padding:60px 80px 0px;
    @media screen and (max-width: 1100px){
      padding:30px 40px 0px;
      font-size: 18px;
    }
    @media screen and (max-width: 767px){
      padding:30px 10px 0px;
      width: 50%;
      font-size: 16px;
    }
    img {
      max-width: 100%;
      display: block;
      margin:auto;
      @media screen and (max-width: 767px){
        max-width: 80%;
      }
    }
    p{
      margin:20px 0 0px 0px;
      line-height: 1.2em;
    }
  }
}

.thank-you{
    font-size: 1.125em;
}
.thank-you-title{
    font-size: 2.222em;
    font-family: 'Gotham Bold';
    display: block;
    text-align: center;
    @media screen and (max-width: 1200px){
      font-size: 2em;
    }
    @media screen and (max-width: 768px){
      font-size: 1.5em;
    }
}
.thank-you-subtitle{
    font-size: 1em;
    padding-top:20px;
    display: block;
    text-align: center;
}
.thank-you-text1{
    font-size: 55px;
    font-family: 'Gotham Medium';
    display: block;
    text-align: center;
    line-height: 1.2em;
    margin-bottom:20px;
    @media screen and (max-width: 1200px){
      font-size: 45px;
    }
    @media screen and (max-width: 768px){
      font-size: 38px;
    }
}
.thank-you-text2{
    font-size: 30px;
    display: block;
    text-align: center;
    @media screen and (max-width: 1200px){
      font-size: 28px;
    }
    @media screen and (max-width: 768px){
      font-size: 22px;
    }
}
.hbs-container{
    width: 100%;
    max-width: 600px;
    margin:auto;
    overflow: hidden;
}

.top-text{
  width: 100%;
  text-align: center;
  h1 {
    font-family: 'Gotham Medium';
    font-size: 3.2em;
    color: #fff;
    padding:0;
    margin:0px auto 10px;
    text-align: center;
    max-width: 1200px;
    @media screen and (max-width: 1200px), screen and (max-height: 1000px) {
      font-size: 2.4em;
    }
    @media screen and (max-width: 768px){
      font-size: 1.8em;
    }
    @media screen and (max-width: 520px){
      font-size: 1.6em;
    }
  }
  h2 {
    font-family: 'Gotham Book';
    font-size: 1.9em;
    color: #fff;
    padding:0;
    margin:0px auto 55px;
    text-align: center;
    max-width: 1200px;
    @media screen and (max-width: 1200px), screen and (max-height: 1000px) {
      font-size: 1.7em;
      margin:0px auto 30px;
    }
    @media screen and (max-width: 768px){
      font-size: 1.4em;
      margin:0px auto 25px;
    }
    @media screen and (max-width: 520px){
      font-size: 1.3em;
      margin:0px auto 15px;
    }
  }
  h3 {
    width: 100%;
    font-family: 'Gotham Medium';
    font-size: 2.5em;
    padding:0;
    margin:0px;
    text-align: center;
    @media screen and (max-width: 1200px), screen and (max-height: 1000px) {
      font-size: 2em;
    }
    @media screen and (max-width: 768px){
      font-size: 1.8em;
    }
    @media screen and (max-width: 520px){
      font-size: 1.5em;
    }
  }
  h1,h2,h3{
    text-shadow: 0px 0px 10px black;
  }
}
.top-btn {
  display: inline-block;
  padding: 16px 90px;
  border-radius: 35px;
  margin:0 auto;
  background-color: #1F9DD9;
  color: #fff;
  cursor: pointer;
  font-family: 'Gotham Bold';
  font-size: 20px;
  &.marg-top {
    margin-top: 60px;
  }
  &:hover{
    background-color: #fbca00;
    color: #1F9DD9;
  }
  @media screen and (max-width: 1200px), screen and (max-height: 1000px) {
    font-size: 17px;
    padding: 12px 45px;
  }
  @media screen and (max-width: 767px){
    font-size: 15px;
  }
  /* @media screen and (max-width: 560px){
    display: none;
  } */
}

.form-errors{
  font-size:13px;
  font-family: 'Gotham Medium';
  &.ad{
    text-align: right;
  }
  p {
    margin: 0 0 5px;
    color: #666;
  }
}

#hs-eu-cookie-confirmation-inner{
  display: none!important;
}