
.order-title {
    display: block;
    font-family: 'Gotham Bold';
    font-size: 1.375em;
    position: relative;
}
.order-title span {
    display: inline-block;
    vertical-align: middle;
}
.order-icon{
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left:-60px;
    top:-10px;
}
.order-subtitle {
    display: block;
    color: #848484;
}
@media screen and (max-width: 1179px){
  .order-icon{
        position: static;
        left:0px;
        top:0px;
        margin-right:10px;
    }
}
.delivery-container{
    padding-bottom:50px;
}
.cart-buttons-container{
    padding-left:0px !important;
    padding-right:0px !important;
    padding-top:20px;
    padding-bottom:15px;
}
.cart-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height:72px;
    background: #ebebeb;
    border:2px solid #ebebeb;
    color: #333;
    cursor: pointer;
    text-align: center;
    padding:10px 0;
    border-radius: 4px;
    flex-wrap: wrap;
    &.deactive{
        cursor: not-allowed;
        opacity:0.5;
    }
}
.cart-buttons:hover{
    font-family: 'Gotham Medium';
    border:2px solid #fbca00;
    &.deactive{
        border:none;
    }
}
.cart-buttons.active{
    font-family: 'Gotham Medium';
    border:2px solid #fbca00;
    &.deactive{
        border:none;
    }
}

.cart-info-container{
    padding-left: 16px !important;
    padding-right:16px !important;
    padding-top:16px;
    padding-bottom:16px;
    background: #fff;
    border-radius: 15px;
    border:1px solid #fbca00;
}
.cart-info-container.typ{
    margin-bottom:100px;
}
@media screen and (max-width: 1179px){
  .cart-info-container.typ{ margin-bottom:50px; }
}
@media screen and (max-width: 767px){
  .cart-info-container.typ{ margin-bottom:30px; }
}

.delivery-address{
    padding-top: 10px;
    .form-errors{
        display: block;
        margin-top:5px;
        margin-left:22px;
        color: #c00;
        font-size: 11px;
    }
}
.delivery-address input[type="text"]{
    border:none;
    display: inline-block;
    width: 100%;
    border-bottom:1px solid #d0d0d0;
    background: transparent;
    padding:6px;
    margin-bottom:15px;
    font-size:0.9em;
    &.show-error{
        border-bottom:1px solid red;
    }
}
.delivery-address input#street{
    width: 70%;
    margin-right:2%;
}
.delivery-address input#number{
    width: 28%;
}
.leaflet-container {
    height: 240px;
    width: 100%;
    margin: 0 auto;
}
.phone-label{
    display: block;
    padding:0 6px;
    margin: 0;
    opacity: 0.6;
    font-size:0.9em;
}
.react-tel-input{
    margin-bottom: 15px;
    z-index: 3;
    &.first-child{
        z-index: 4;
    }
    .flag-dropdown{
        border:none;
        border-right: 1px solid #cacaca;
    }
    .form-control{
        width: 100%;
        font-family: 'Gotham Book';
        &.show-error{
            border-color: red;
        }
    }
}

.order-hour{
    padding-top:25px;
}
.info-beach-map{
    width:100%;
    padding-bottom:15px;
    text-align: center;
}
.info-beach-map-typ{
    width:100%;
    padding-bottom:15px;
    text-align: center;
}
.faq-container {
    width:100%;
    padding:50px 0;
}
.obbligatori{
    display: inline-block;
    color: #666;
    font-size: 12px;
}

.google-places-autocomplete__suggestions-container{
    top:28px;
    z-index: 2000;
}
.google-places-autocomplete__suggestion{
    font-size: 0.9em;
    padding: 5px;
    cursor: pointer;
    &:hover{
        background-color: #f1f1f1;
    }
}
/* .google-places-autocomplete{
    position: relative;
    z-index: 1;
    input{
        padding-right: 50px!important;
        width: 100%!important;
    }
} */
.GooglePlacesAutocomplete{
    position: relative;
    z-index: 2;
    .reset{
        display: none;
        position: absolute;
        z-index: 2;
        top: 1px;
        right: 1px;
        text-align: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #f1f1f1;
        color: #666;
        cursor: pointer;
        @media screen and (max-width: 768px){
            width: 22px;
            height: 22px;
        }
    }
}