/* All HubSpot Forms
   ========================================================================== */

/* Form Field (selector for form field wrapper) */
.hs-form .hs-form-field {}

/* Descriptions (targets class applied to Help Text divs) */
.hs-form .hs-field-desc {}

/* Labels (selects field labels and error messages) */
.hs-form label {}
.hs-form .hs-form-field > label {}
.hs-input{
    padding: 20px;
}

/* Inputs (selectors for all inputs)  */
.hs-form input[type="text"],
.hs-form input[type="password"],
.hs-form input[type="datetime"],
.hs-form input[type="datetime-local"],
.hs-form input[type="date"],
.hs-form input[type="month"],
.hs-form input[type="time"],
.hs-form input[type="week"],
.hs-form input[type="number"],
.hs-form input[type="email"],
.hs-form input[type="url"],
.hs-form input[type="search"],
.hs-form input[type="tel"],
.hs-form input[type="color"],
.hs-form input[type="file"],
.hs-form textarea,
.hs-form select {
    padding: 5px;
}

/* Inputs in focus (selectors for all inputs when clicked)  */
.hs-form input[type="text"]:focus,
.hs-form input[type="password"]:focus,
.hs-form input[type="datetime"]:focus,
.hs-form input[type="datetime-local"]:focus,
.hs-form input[type="date"]:focus,
.hs-form input[type="month"]:focus,
.hs-form input[type="time"]:focus,
.hs-form input[type="week"]:focus,
.hs-form input[type="number"]:focus,
.hs-form input[type="email"]:focus,
.hs-form input[type="url"]:focus,
.hs-form input[type="search"]:focus,
.hs-form input[type="tel"]:focus,
.hs-form input[type="color"]:focus,
.hs-form input[type="file"]:focus,
.hs-form textarea:focus,
.hs-form select:focus {}

/* Multi-line inputs (selectors to target multi-line fields */
.hs-form textarea {}
.hs-form textarea:focus {}

/* Dropdowns (selectors for dropdowns) */
.hs-form select {}
.hs-form select:focus {}

/* Multi-select (selectors for multi-select fields) */
.hs-form form.hs-form .hs-form-field ul.inputs-list {}

.hs-form form.hs-form .hs-form-field ul.inputs-list li input {}
.hs-form input[type="radio"] {}
.hs-form input[type="checkbox"] {}

/* Required (selectors for fields, when they do not pass validation) */
.hs-form input:focus:required:invalid,
.hs-form textarea:focus:required:invalid,
.hs-form select:focus:required:invalid {}

.hs-form input:focus:required:invalid:focus,
.hs-form textarea:focus:required:invalid:focus,
.hs-form select:focus:required:invalid:focus {}

/* Error message (selector for validation messages) */
.hs-form .hs-error-msgs label{}

/* Placeholder Text (styles the placeholder attribute text) */


/* Multi Column Form (selectors for fieldsets and field wrappers)
   ========================================================================== */

.hs-form .hs-form fieldset.form-columns-1 {}
.hs-form .hs-form fieldset.form-columns-1 .hs-form-field {}

.hs-form .hs-form fieldset.form-columns-2 {}
.hs-form .hs-form fieldset.form-columns-2 .hs-form-field {}

.hs-form .hs-form fieldset.form-columns-3 {}
.hs-form .hs-form fieldset.form-columns-3 .hs-form-field {}

/* Submit buttons (selectors for all non-CTA buttons)
   ========================================================================== */

body .hs-button.primary,
body input[type="submit"],
body input[type="button"] {}

body .hs-button.primary:hover,
body input[type="submit"]:hover,
body input[type="button"]:hover {}

body .hs-button.primary:focus,
body input[type="submit"]:focus,
body input[type="button"]:focus {}

fieldset {
  max-width: none!important;
}
.hs-richtext{
    color: #fff !important;
}

.hs-form-field{
  margin-bottom: 10px;
}
.hs-form-field label{
  font-size: 16px; font-weight: normal; text-align: left; display: block; margin-bottom:10px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.hs-form-field input[type="text"], .hs-form-field input[type="tel"], .hs-form-field textarea, .hs-form-field input[type="email"], .hs-form-field select{
  width: 100% !important;
  font-weight: normal;
  background-color: #fff !important;
  color: #333 !important;
  height: 41px;
  font-size: 15px;
  border:none;
  margin-bottom: 10px;
}
.hs-form-field textarea{
  height: 100px;
  resize: none;
}
.legal-consent-container{
  font-size: 12px;
  text-align: left;
}
.legal-consent-container ul{
  margin-left: 0px;
  padding-left: 0px;
}
.legal-consent-container li{
  list-style: none;
}
.field.hs-child_name,
.field.hs-child_s_name_2,
.field.hs-child_s_name_3,
.field.hs-child_s_name_4,
.field.hs-child_s_name_5{
  float: left !important; width: 50% !important;
}
.legal-consent-container .hs-error-msgs label {
    color: #23408F!important;
}
.hs-button{
  width: 100%;
    background: #fff;
    color: #23408F;
    padding: 10px 10px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1em;
    border-radius: 0px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 50px;
}
.hs-button:hover{
  background-color: #23408F;
  color: #fff;
}
.hs-error-msgs{
  margin-left: 0px;
  padding-left: 0px;
  color: #23408F;
}
.hs-error-msgs li{list-style: none;}
.hs-form-required{
  color: #fff;
}
.hs_which_session_would_you_like_to_attend_{
  position:relative;
  padding-bottom:35px;
}
.hs_which_session_would_you_like_to_attend_ legend{
    display: block;
    text-align: left;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size:1rem;
}
.hs-fieldtype-date {
  width: 50%;
    float: left;
}