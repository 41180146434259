.top-location{
    width:100%;
    min-height: 280px;
    padding-top:70px;
    position: relative;
    background-color: #f6f6f6;
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (max-width: 767px){
        padding-top:35px;
    }
    &.in-cart{
        padding-top:110px;
        @media screen and (max-width: 1179px){
            padding-top:85px;
        }
        @media screen and (max-width: 767px){
            padding-top:35px;
        }
    }
}
.location-name{
    .top-title {
        font-family: 'Gotham Book';
        font-size: 2.1875em;
        margin:2px 20px 0 0;
        padding: 0px;
        text-align: center;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        @media screen and (max-width: 1100px){
            font-size: 1.8em;
        }
        @media screen and (max-width: 767px){
            font-size: 1.6em;
        }
    }
}
.restaurant-list{
    padding:30px 0px 0 0;
    min-height: 50vh;
    h1 {
        font-family: 'Gotham Medium';
        font-size: 1.85em;
        margin:0 0 10px;
        padding: 0px;
        text-align: center;
        position: relative;
        @media screen and (max-width: 1100px){
            font-size: 1.8em;
        }
        @media screen and (max-width: 767px){
            font-size: 20px;
        }
    }
    h2 {
        font-family: 'Gotham Medium';
        font-size: 1.5em;
        margin:0px 0px 0 0;
        padding: 0px;
        text-align: center;
        position: relative;
        @media screen and (max-width: 1100px){
            font-size: 1.5em;
        }
        @media screen and (max-width: 767px){
            font-size: 16px;
        }
    }
    .spiacenti {
        padding:80px 0 100px;
        text-align: center;
        position: relative;
        font-size: 18px;
        img{
            display: block;
            margin: 0 auto 30px;
        }
    }
}
.is-zone {
    input{
        font-family: 'Gotham Bold';
        font-size: 1.2em;
        @media screen and (max-width: 1100px){
            font-size: 1.2em;
        }
        @media screen and (max-width: 767px){
            font-size: 1em;
        }
    }
}
.city-search-zone {
  margin: auto;
  border: 1px solid #CBC7C7;
  padding: 10px 20px;
  border-radius: 30px;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  @media screen and (max-width: 767px){
    padding: 5px 20px;
  }
}

.single-city-content{
    width: 100%;
    padding:0 15px 35px;
    margin-bottom: 25px;
    border-bottom: 2px solid #ddd;
    &:last-child{
        border:none;
    }
    .single-city-all{
        text-align: right;
        padding-bottom: 10px;
        a{
            color: #009fe2;
        }
    }
    .single-city-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 1280px){
            width: calc(100% - 50px);
        }
        @media screen and (max-width: 980px){
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
        }
        .single-city-cat{
            width: 25%;
            padding: 0 3%;
            text-align: center;
            font-family: 'Gotham Medium';
            font-size: 1.3em;
            line-height: 1.1;
            color: #000;
            position: relative;
            .food-img{
                display: block;
                margin:0 auto 15px;
                max-width: 50%;
            }
            .view-all{
                display: block;
                margin:15px auto 0px;
                cursor: pointer;
                font-family: 'Gotham Bold';
                font-size: 14px;
                color: #009fe2;
            }
            @media screen and (max-width: 980px){
                width: 100%;
                padding-bottom: 20px;
                text-align: left;
                .single-city-cat-name{
                    display: inline-block;
                    vertical-align: middle;
                }
                .food-img{
                    display: inline-block;
                    margin:0 15px 0 0;
                    max-width: 60px;
                }
                .view-all{
                    display: inline-block;
                    margin:0 0 0 15px;
                }
            }
            @media screen and (max-width: 480px){
                padding: 0 0 20px 0;
                font-size: 1em;
                .food-img{
                    max-width: 30px;
                }
                .view-all{
                    display: block;
                    margin:0;
                }
            }
        }
        .single-city-grid{
            width: 75%;
            @media screen and (max-width: 980px){
                width: calc(100% - 80px);
            }
        }
    }
}

#simple-menu .MuiList-padding{
    padding-top:20px;
    padding-bottom:20px;
}
#simple-menu .MuiMenuItem-gutters {
    padding-left: 30px;
    padding-right: 30px;
}
#simple-menu a, #simple-menu a:hover {
    font-family: 'Gotham Bold';
    font-size: 1.286em;
    color: #000;
    text-decoration: none;
}
.food-type-container{
    padding:0px 0 0 0!important;
    margin-top:30px;
    border-bottom:2px solid #ddd;
    background-color: #f6f6f6;
    position: sticky;
    top: 0px;
    z-index: 100;
    @media screen and (max-width: 960px){
        margin-top:0px;
    }
}
.mobile-list {
    display: none;
    &.mobile-list-show {
        display: block;
    }
}
.categories-grid{
    width:16.6667%;
    &.isSticky {
        width:14.285%;
    }
    &.zone-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        padding:10px;
        font-size: 12px;
        font-family: 'Gotham Medium';
        background-color: #fbca00;
    }
    @media screen and (max-width: 768px){
        width:33.333%;
    }
}
.food-paper{
    height: 100%;
    padding:0 0px;
}
.food-type{
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 0.9em;
    cursor: pointer;
    border-bottom:6px solid transparent;
    padding:0 10px 6px;
    text-align: center;
    &:hover{
        border-bottom:6px solid #fbca00;
    }
    &.active{
        border-bottom:6px solid #fbca00;
        background-color: #fff;
    }
    .food-type-text {
        display: inline-block;
        vertical-align: top;
        &.isSticky{
            width: 70%;
            text-align: left;
            font-size: 12px;
            padding-top: 10px;
        }
    }
    .food-type-img{
        display: block;
        height: 68px;
        text-align: center;
        margin-bottom: 10px;
        padding-top: 10px;
        @media screen and (max-width: 768px){
            height: 55px;
        }
        .food-img{
            max-width: 50%;
            max-height: 58px;
            object-fit: contain;
            display: inline-block;
            
            @media screen and (max-width: 768px){
                max-height: 45px;
            }
        }
        &.isSticky{
            display: inline-block;
            vertical-align: top;
            height: auto;
            width: 30%;
        }
    }
}

.chip-container{
    padding: 0 !important;
}
.chip-container span{
    font-size: 0.95em;
    font-family: 'Gotham Book';
}

.restaurants-container{
    padding: 50px 0 !important;
}
.restaurants-grid {
    flex-wrap: wrap;
    justify-content: space-between;
}
/* .restaurants-grid a{
    display: inline-block;
    width: 365px;
    max-width: 100%;
} */
.restaurants-grid:after {
  content: "";
  width: 365px;
  max-width: 100%;
}
@media screen and (max-width: 1179px){
    /* .restaurants-grid {
        justify-content: center;
    } 
    .restaurants-grid a{
        display: inline-block;
        width: 320px !important;
        max-width: 100% !important;
    }*/
    .MuiCard-root, .restaurants-grid:after {
        width: 320px !important;
        max-width: 100% !important;
    }
}
@media screen and (max-width: 1024px){
    .restaurants-grid {
        justify-content: space-around;
    }
    .chip-container{
        text-align: center !important;
    }
}

.shop-name {
    padding:0px;
    margin:0px 0px 0px 0;
    color: #000;
    display: block;
    font-family: 'Gotham Bold';
    font-size: 1.31em;
    text-align: left;
    .shop-city{
        font-size: 0.85em;
    }
}
.shop-content {
    position: relative;
    z-index: 1;
    padding-bottom: 0px!important;
}
.shop-alerts{
    width: 100%;
    position: absolute;
    bottom:-10px;
    left:5px;
    height: auto!important;
    display: flex;
    justify-content: flex-start;
    align-content: flex-end;
    flex-wrap: wrap;
}
.shop-first{
    text-transform: uppercase;
    text-align: center;
    padding:4px 10px 2px;
    background: #009fe2;
    font-size:0.9em;
    font-family: 'Gotham Medium';
    margin: 2px 5px;
    color: #fff;
}

.food-chip {
    margin: 10px 15px 0px 0;
    background-color: #ebebeb !important;
}
.food-chip:hover, .food-chip.active {
    background-color: rgb(206, 206, 206) !important;
}
.partnership{
    width: 100%;
    background-color: #fff;
    padding: 50px 0;
    .partnership-container{
        text-align: center;
        @media screen and (max-width: 1120px){
            justify-content: center;
        }
    }
    .partnership-text{
        max-width: 50%;
        .tit{
            display: inline-block;
            font-size: 1.2em;
            margin-bottom: 20px;
        }
        @media screen and (max-width: 1120px){
            max-width: none;
        }
    }
    .partnership-logo{
        img{
            max-width: 100%;
        }
    }
}