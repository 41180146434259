.cities {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.cities a{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 6px rgba(0,0,0,.2);
    text-transform: none;
    font-size: 1.5em;
    padding: 20px;
    width: 255px;
    height: 135px;
    border: 1px solid;
    line-height: 1.5em;
    margin:20px 28px;
    background-color: #fff;
    border-color: #fff;
    color: #009fe2;
    border-radius: 10px;
    font-family: 'Gotham Medium';
}
.cities a:hover {
    background-color: #fbca00;
    border-color: #fbca00;
    text-decoration: none;
}
.cities a span {
    display: inline-block;
}
.cities.bottom a{
    /* font-size: 1.56em;
    width: 320px;
    margin:20px 33px; */
    color: #000;
}
.cities.bottom a:hover {
    background-color: #fbca00;
    border-color: #fbca00;
    text-decoration: none;
}
@media screen and (max-width: 768px){
  .cities a, .cities.bottom a{
    font-size: 1.4em;
    width: 250px;
    height: auto;
    margin:15px 20px;
  }
}
.MuiAutocomplete-root{
    width: 290px;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 359px){
        width: 250px;
    }
}
.MuiInputLabel-shrink {
    display: none!important;
}
.MuiInput-underline:before, .MuiInput-underline:after {
    display: none!important;
}
label + .MuiInput-formControl {
    margin-top: 0px!important;
}