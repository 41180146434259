.code {
    margin-left: 5%;
    width: 90%;
}

h1 {
    text-align: center;
}

pre code {
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    padding: 20px;
}

