.link_scheda{
    display: block;
}
.shop-card{
    position: relative;
    color: #666666;
    width: 365px;
    /* height: 90%; */
    font-size: 0.95em;
    max-width: 100%;
    margin-bottom: 36px;
    overflow: hidden;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    @media screen and (max-width: 1200px){
        width: 315px;
    }
    &.isPortal{
        margin-bottom: 0px;
    }
    .shop-card-media{
        height: 178px;
        background-color: #f8f8f8;
        position: relative;
        div {
            height: 100%;
        }
        .slick-slider {
            overflow: hidden;
            cursor: grab;
        }
        .slick-list {
            transform: scale(1.0);
            transition: all 0.3s;
        }
        .shop-card-media-img{
            width: 100%;
            height: 100%;
            background-color: #c4e0e3;
            background-position: center!important;
            background-repeat: no-repeat!important;
            background-size: cover!important;
            &.lonely {
                transform: scale(1.0);
                transition: all 0.3s;
            }
            &.no-img{
                background-size: contain!important;
            }
        }
    }
    .shop-card-content{
        padding: 16px 5px 65px 16px;
        /* position: relative; */
        .consegna-gratuita{
            font-size: 15px;
            font-family: 'Gotham Medium';
            color: #00B931;
            display: block;
            padding:5px 0 0;
        }
        .buy-type-container{
            position: absolute;
            bottom: 0px;
            left:0px;
            border:1px solid #fff;
            background-color: #DCF0F9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            min-height: 42px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        .delivery-type{
            display: inline-block;
            vertical-align: middle;
            margin-right: 0px;
            font-size: 11px;
            line-height: 1.2em;
            color: #000;
            padding: 7px 10px;
            width: 100%;
            border-right:1px solid #fff;
            &:last-child{
                border:none;
            }
            @media screen and (max-width: 1180px){
                font-size: 11px;
                &:first-child{
                    margin-right: 5px;
                }
            }
            @media screen and (max-width: 359px){
                font-size: 9px;
                &:first-child{
                    margin-right: 5px;
                }
            }
            img{
                display: inline-block;
                vertical-align: middle;
                margin-right: 7px;
            }
            span {
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 30px);
            }
        }
    }
    .slick-prev, .slick-next {
        width: 30px !important;
        height: 30px !important;
        z-index: 5;
    }
    .slick-prev {
        opacity: 0;
        left: 10px !important;
        transition: all 0.3s;
    }
    .slick-next {
        opacity: 0;
        right: 10px !important;
        transition: all 0.3s;
        transform: translate(0, -50%) rotate(180deg) !important;
    }
    .slick-slide img {
        max-width: 100% !important;
    }
    &:hover{
        .slick-prev, .slick-next {
            opacity: 1;
        }
        .slick-list {
            transform: scale(1.1);
        }
        .shop-card-media-img.lonely{
            transform: scale(1.1);
        }
    }
    @media screen and (max-width: 725px){
        .slick-prev, .slick-next {
            width: 30px !important;
            height: 30px !important;
        }
        .slick-prev {
            left: 5px !important;
        }
        .slick-next {
            right: 5px !important;
            transform: translate(0, -50%) rotate(180deg) !important;
        }
    }
}

.singolo-ingrediente{
    text-transform: lowercase;
    &:not(:last-child):after{
        content: ", ";
    }
}

.share-icon {
    width:40px;
    height: 40px;
    text-align: center;
    line-height: 36px;
    background-color: #000;
    display: inline-block;
    vertical-align: middle;
    margin:0 10px;
    &.fb { background-color: #4267B2; }
    &.tw { background-color: #1A91DA; }
    &.wap { background-color: #00E676; }
    img{
    margin:0!important;
    display: inline!important;
    }
}
.card-noimage {
    background-color: #c4e0e3!important;
    background-size: contain!important;
}